import React from "react";
import { httpPost } from "../../http";
import {
  message,
  Modal,
  Form,
  Input
} from "antd";

const FormItem = Form.Item;

class AddFormContainer extends React.Component {
  state = {
    photo_url: "",
  };

  render() {
    const that = this;
    const {
      modalVisibleAdd,
      form,
      handleModalVisibleAdd,
      getDeviceList,
      modalTitleAdd,
      initData,
      type,
    } = this.props;

    const okHandle = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        
        if (initData.id) {
          let submit_value = {
            id: initData.id,
            ...fieldsValue,
          };
          httpPost("/api/v1/device/update", JSON.stringify(submit_value))
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              if (data.code === 0) {
                that.setState({
                  photo_url: "",
                });
                handleModalVisibleAdd();
                getDeviceList(type);

                form.resetFields();
              } else {
                message.error(data.msg);
              }
            })
            .catch((err) => {
              message.error("Interface error: update_device");
            });
        } else {
          let submit_value = {
            ...fieldsValue,
          };
          httpPost("/api/v1/device/add", JSON.stringify(submit_value))
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              if (data.code === 0) {
                that.setState({
                  photo_url: "",
                });
                handleModalVisibleAdd();
                getDeviceList(type);
                form.resetFields();
              } else {
                message.error(data.msg);
              }
            })
            .catch((err) => {
              message.error("Interface error: add_device");
            });
        }
        
      });
    };
    
    return (
      <Modal
        destroyOnClose
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        okText="Save"
        onOk={okHandle}
        width={500}
        onCancel={() => handleModalVisibleAdd()}
      >
        <Form layout="vertical" hideRequiredMark>
          
          <FormItem label="Device ID">
            {form.getFieldDecorator("device_id", {
              rules: [
                {
                  required: true,
                  message: "Please input device id.",
                },
                {
                  pattern: /^[a-zA-Z0-9]{12}$/,
                  message: "Please input 12 digit.",
                },
              ],
              initialValue: initData.device_id,
            })(<Input />)}
          </FormItem>
          <FormItem label="RFID">
            {form.getFieldDecorator("rfid", {
              rules: [
                {
                  required: true,
                  message: "Please input rfid.",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Please input 10 digit.",
                },
              ],
              initialValue: initData.rfid,
            })(<Input />)}
          </FormItem>
      
          
        </Form>
      </Modal>
    );
  }
}
const AddForm = Form.create()(AddFormContainer);
export default AddForm;
