import React from "react";
import { Tabs } from "antd";
import { withRouter } from "react-router-dom";
import BindProfile from "./BindProfile"
import BindVehicle from "./BindVehicle"

import "./bind.css"

const { TabPane } = Tabs;

class Bind extends React.Component {
  state = {
    activeKey: "profile"
  };

  componentDidMount() {
  };
  callback = (key) => {
    this.setState({
      activeKey: key
    });
  }
  render() {
    
    return (
      <div className="box">
        <Tabs onChange={this.callback} type="card" defaultActiveKey="profile">
          <TabPane tab="Profile" key="profile">
            <BindProfile activeKey={this.state.activeKey} />
          </TabPane>
          <TabPane tab="Vehicle" key="vehicle">
            <BindVehicle activeKey={this.state.activeKey} />
          </TabPane>
        </Tabs>
        
      </div>
    );
  }
}

export default withRouter(Bind);
