import React from "react";
import {
  Table,
  Divider,
  Popconfirm,
  message,
  Button,
  Form,
  Input,
  Icon
} from "antd";
import { httpGet, httpDelete } from "../../http";
import AddForm from "./AddForm.js";
import TimeForm from "./TimeForm.js";
import "./profiles.css"
const { Search } = Input;

class ListForm extends React.Component {
  state = {
    modalVisibleAdd: false,
    modalVisibleTime: false,
    modalTitleAdd: "",
    editValues: {},
    selectedRowKeys: [],
    data: [],
    type: 0,
    pageSize: 10
  };

  componentDidMount() {
    this.getVehicleList(0);
  }

  getVehicleList = (type) => {
    httpGet("/api/v1/vehicle/get_vehicle_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          this.setState({
            data: data.data,
          });
        }
      })
      .catch((err) => {
        message.error("Interface error: get_vehicle_list");
      });
  };

  downloadWorkersList = () => {
    let url = "/api/v1/worker/download";
    window.open(url);
  };

  handleModalVisibleAdd = (flag, title, record) => {
    this.setState({
      modalVisibleAdd: !!flag,
      modalTitleAdd: title,
      editValues: record || {},
    });
  };

  handleModalVisibleTime = (flag, vehicle_id) => {
    this.setState({
      modalVisibleTime: !!flag,
      selectedRowKeys: vehicle_id
    });
  };

  delete_confirm = (id) => {
    httpDelete("/api/v1/vehicle/delete_vehicle", JSON.stringify({ id: id }))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          message.info("Delete Success!");
          this.getVehicleList(this.state.type);
        }
      })
      .catch((err) => {
        message.error("Interface error: delete_vehicle");
      });
  };

  columns = [
    {
      title: "On Site",
      dataIndex: "onsite",
      align: "center",
      key: "onsite",
      render: (data) => (
        <span>
          {data ? <b className="green"></b> : <b className="red"></b>}
        </span>
      ),
      sorter: (a, b) => a.onsite - b.onsite
    },
    {
      title: "Plate",
      dataIndex: "nric",
      key: "nric",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Mobile Number",
      dataIndex: "contract_title",
      key: "contract_title",
    },
    {
      title: "Device ID",
      dataIndex: "device_id",
      key: "device_id",
    },
    {
      title: "In Time",
      dataIndex: "in_time",
      key: "in_time",
    },
    {
      title: "Out Time",
      dataIndex: "out_time",
      key: "out_time",
    },
    {
      title: "Movement",
      dataIndex: "movement",
      key: "movemoent",
      align: "center",
      render: (text, record) => (
        <Icon 
          type="history" 
          style={{ fontSize: 22, cursor: 'pointer', color: '#1890ff' }}
          onClick={() => this.handleModalVisibleTime(true, [record.id])}
        />
      ),
    },

    {
      title: <Icon type="plus-circle" style={{ fontSize: 22, cursor: 'pointer' }}
        onClick={() => this.handleModalVisibleAdd(true, "Add Vehicle", {})}
      />,
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <span>
          <Button
            type="link"
            onClick={() =>
              this.handleModalVisibleAdd(true, "Edit Vehicle", record)
            }
          >
            Edit
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            placement="top"
            title="Are you sure to delete this item?"
            onConfirm={() => this.delete_confirm(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onSearch = (value) => {
    let workerSearch = this.state.data.filter((item, index) => {
      
      let plate = item.nric ? item.nric.indexOf(value) !== -1 : null ;
      let device_id = item.device_id ? item.device_id.indexOf(value) !== -1 : null ;
      if ( plate || device_id) {
        return item;
      }
      return ''
    });

    if (workerSearch.length > 0) {
      this.setState({
        data: workerSearch,
      });
    } else {
      message.error("error: The search content cannot be found.");
    }
  };
  resetWorkList = () => {
    this.refs.search.input.state.value= undefined
    this.getVehicleList(0)
  };
  onShowSizeChange = (current, size) => {
    this.setState({
      pageSize: size
    })
  }

  render() {
    const parentMethodsAdd = {
      handleModalVisibleAdd: this.handleModalVisibleAdd,
    };
    const parentMethodsTime = {
      handleModalVisibleTime: this.handleModalVisibleTime,
    };

    return (
      <div className="box profiles">
        <div className="table-operations">
          <Search
            ref="search"
            placeholder="Search Plate or Device ID"
            enterButton
            onSearch={this.onSearch}
            style={{ width: 360, marginLeft: 10 }}
           
          />
          <Button 
            onClick={() => this.resetWorkList()} 
            type="primary"
            style={{ marginLeft: 1 }}>
            Reset
          </Button>

          <Button 
            icon="download" 
            onClick={() => this.downloadWorkersList()} 
            type="primary"
            style={{display: 'none'}}
            >Download</Button>
        </div>
        <Table
          rowKey={(record) => record.id}
          columns={this.columns}
          dataSource={this.state.data}
          pagination={
            {
              pageSize: this.state.pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.onShowSizeChange
            }
          }
        ></Table>
        <AddForm
          {...parentMethodsAdd}
          type={this.state.type}
          getVehicleList={this.getVehicleList}
          modalVisibleAdd={this.state.modalVisibleAdd}
          modalTitleAdd={this.state.modalTitleAdd}
          initData={this.state.editValues}
        />
        <TimeForm
          {...parentMethodsTime}
          modalVisibleTime={this.state.modalVisibleTime}
          workerId={this.state.selectedRowKeys}
        />
      </div>
    );
  }
}

const Profiles = Form.create({ name: "add_record" })(ListForm);
export default Profiles;
