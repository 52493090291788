import React, { Component } from "react";
import { Switch } from 'react-router'
import AuthRouter from "../Router/AuthRouter.js";
import Outline from "../Outline";

import Home from "../Home";
import Profiles from "../Profiles";
import Vehicles from "../Vehicle";
import Geofencing from "../Geofencing";
import History from "../History";
import Alert from "../Alert";
import Location from "../Location";
import Uuid from "../Uuid";
import Bind from "../Bind";
import Inout from "../Inout";
import Device from "../Device"
import NotFound from "../404"

export default class SubRouter extends Component {
  render() {
    return (
      <Outline>
        <Switch>
          <AuthRouter exact path="/" component={Home}></AuthRouter>
          <AuthRouter path="/home" component={Home}></AuthRouter>
          <AuthRouter path="/profiles" component={Profiles}></AuthRouter>
          <AuthRouter path="/vehicles" component={Vehicles}></AuthRouter>
          <AuthRouter path="/geofencing" component={Geofencing}></AuthRouter>
          <AuthRouter path="/history" component={History}></AuthRouter>
          <AuthRouter path="/alert" component={Alert}></AuthRouter>
          <AuthRouter path="/location" component={Location}></AuthRouter>
          <AuthRouter path="/uuid" component={Uuid}></AuthRouter>
          <AuthRouter path="/bind" component={Bind}></AuthRouter>
          <AuthRouter path="/inout" component={Inout}></AuthRouter>
          <AuthRouter path="/device" component={Device}></AuthRouter>

          <AuthRouter path="*" component={NotFound}></AuthRouter>
        </Switch>
      </Outline>
    );
  }
}
