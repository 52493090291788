import React from "react";
import { Row, Col, Card, Button, message, Input } from "antd";
import { httpGet, httpPost } from "../../http";

import LinksIcon from "../../images/links-icon.png"

import "./bind.css"

class BindProfile extends React.Component {
  constructor(props) {
    super(props);
    this.profileRef = React.createRef();
    this.deviceRef = React.createRef();
  }

  state = {
    profileData: [],
    inputProfile: '',
    signProfile: false,

    deviceData: [],
    inputDevice: '',
    signDevice: false,

    loading: false,
    bindObj: {}
  };

  componentDidMount() {
    this.getWorkersList()
    this.getDevicesList()
    this.profileRef.current.focus()
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeKey !== prevProps.activeKey && this.props.activeKey === "profile") {
      this.initData()
      if (this.profileRef.current) {
        setTimeout(() => {
          this.profileRef.current.focus();
        }, 100);
      }
    }
  }

  getWorkersList = (type) => {
    httpGet("/api/v1/worker/get_workers_list")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          this.setState({
            profileData: data.data,
          });
        }
      })
      .catch((err) => {
        message.error("Interface error: get_workers_list");
      });
  };
  getDevicesList = () => {
    httpGet("/api/v1/device/get_idle_devices")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          this.setState({
            deviceData: data.data,
          });
        }
      })
      .catch((err) => {
        message.error("Interface error: get_device_list");
      });
  };
  filterProfile = (value) => {
    let arr = []
    arr = this.state.profileData.filter(item => {
      return String(item.csn_number) === value
    })
    if (arr.length > 0) {
      this.setState({ 
        signProfile: true,
        inputDevice: arr[0].device_id && arr[0].device_id,
        bindObj: arr[0],
      });
      this.deviceRef.current.focus()
    } else {
      this.setState({ 
        signProfile: false,
        bindObj: {},
      });
      this.profileRef.current.focus()
      
      message.error("Please input right csn_number.");
      
      
    }
  }

  onChangeProfile = e => { 
    let value = e.target.value

    this.setState({
      inputProfile: value
    })
    if (value === "") {
      this.setState({
        signProfile: false,
        bindObj: {}
      })
      return
    } 

    if (value.length >= 10) {
      this.filterProfile(value)
    }
    
  };
  
  filterIdleDevice = (value) => {
    let arr = []
    arr = this.state.deviceData.filter(item => {
      return item.device_id === value
    })
    if (arr.length > 0) {
      this.setState({ 
        signDevice: true,
      });
    } else {
      this.setState({ 
        signDevice: false,
      });
      this.deviceRef.current.focus()
      
      message.error("Please input idle device.");
      
      
    }
  }
  onChangeDevice = e => { 
    let value = e.target.value
    value = value && value.trim()

    this.setState({
      inputDevice: value
    })
    if (value.length >= 10) {
      let arr = []
      arr = this.state.profileData.filter(item => {
        return String(item.device_id) === value
      })
      if (arr.length > 0) {
        this.setState({ 
          signProfile: true,
          signDevice: true,
          inputProfile: arr[0].csn_number && arr[0].csn_number,
          bindObj: arr[0]
        });
      } else {
        this.filterIdleDevice(value)
      }
    }
    
  };
  
  bind = () => {
    // check param
    if (!this.state.bindObj.id) {
      this.profileRef.current.focus()
      message.error("Please input csn number");
      return
    }
    if (!this.state.signProfile) {
      this.profileRef.current.focus()
      message.error("Please input right csn number");
      return
    }
    if (!this.state.inputDevice) {
      this.deviceRef.current.focus()
      message.error("Please input device id");
      return
    }
    if (!this.state.signDevice) {
      this.deviceRef.current.focus()
      message.error("Please input right device");
      return
    }
    let submit_value = {
      worker_id: this.state.bindObj.id,
      device_id: this.state.inputDevice
    };
    httpPost("/api/v1/device/bind", JSON.stringify(submit_value))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          message.success( this.state.bindObj.csn_number + " bound " + this.state.inputDevice + " success.");
          // init data
          this.initData()
          this.profileRef.current.focus()
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("Interface error: bind device");
      });
  }
  unbind = ()=> {
    // check param
    if (!this.state.bindObj.id) {
      message.error("Please input csn number");
      return
    }
    
    if (!this.state.signProfile) {
      this.profileRef.current.focus()
      message.error("Please input right profile");
      return
    }

    let submit_value = {
      device_id: this.state.inputDevice
    };
    httpPost("/api/v1/device/unbind", JSON.stringify(submit_value))
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.code === 0) {
          message.success( this.state.bindObj.csn_number + " unbound success.");
          // init data
          this.initData()
          this.deviceRef.current.focus()
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("Interface error: unbind device");
      });
  }
  initData = () => {
    this.getDevicesList()
    this.getWorkersList()
    this.setState({
      inputProfile: '',
      signProfile: false,
      inputDevice: '',
      signDevice: false,
      loading: false,
      bindObj: {}
    })
    
  }

  callback = (key) => {
    console.log(key);
  }
  render() {
    
    return (
      <Row gutter={16}>
        <Col span={8} offset={1}>
          <Card title="Profile" style={{height: 200}}>
            <Row>
              <Col span={6}>
                <span className="label">CSN Number:</span>
              </Col>
              <Col span={18}>
                <Input 
                  ref={this.profileRef}
                  value={this.state.inputProfile}
                  onChange={this.onChangeProfile}
                  allowClear/>
              </Col>
            </Row>
            
          </Card>
        </Col>
        <Col span={1} style={{textAlign: 'center'}}>
          <img src={ LinksIcon } width="30" alt="" style={{marginTop:80}}/>
        </Col>
        <Col span={8}>
          <Card title="Device" style={{height: 200}}>
            <Row>
              <Col span={4}>
                <span className="label"> Device ID:</span>
              </Col>
              <Col span={20}>
                <Input 
                  ref={this.deviceRef}
                  value={this.state.inputDevice}
                  onChange={this.onChangeDevice}
                  allowClear/>
              </Col>
            </Row>
            
            <br />
            <br />
            <Button 
              type="primary" 
              className={this.state.bindObj && this.state.bindObj.device_id ? 'hide': ''}
              onClick={() => this.bind()}>
              Bind
            </Button>
            <Button 
              type="danger" 
              className={this.state.bindObj && this.state.bindObj.device_id ? '': 'hide'}
              onClick={() => this.unbind()}>
                Unbind
            </Button>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default BindProfile;
